(function ($) {

  "use strict";

  function Common () {
    let bindSmoothScrollToElement = function () {
      // handle links with @href started with '#' only
      $(document).on('click', 'a[href^="#"]:not([data-toggle="tab"])', function(e) {
        // target element id
        var id = $(this).attr('href');

        // target element
        var $id = $(id);
        if ($id.length === 0) {
          return;
        }

        // prevent standard hash navigation (avoid blinking in IE)
        e.preventDefault();

        // top position relative to the document
        var pos = $id.offset().top;

        if (id !== 'header') {
          pos -= 100;
        }

        // animated top scrolling
        $('body, html').animate({scrollTop: pos});
      });
    };

    let bindHambuger = function ($header) {
      let $hamb = $('.js-hamburger', $header);
      let $navigation = $('.js-nav', $header);

      $hamb.on('click', function () {
        let $self = $(this);

        $self.find('> div').toggleClass('active');
        $('body').toggleClass('mobile-nav-active');
        $navigation.toggleClass('active');

        if ($navigation.hasClass('active')) {
          $('body').addClass('stop-scrolling');
          $('body').bind('touchmove', function(e){e.preventDefault()});
        } else {
          $('body').removeClass('stop-scrolling');
          $('body').unbind('touchmove');
        }
      });
    };

    let init = function (parentElement) {
      let $header = $('.js-header', parentElement);

      if ($header.length) {
        bindHambuger($header);
      }

      bindSmoothScrollToElement();
    };

    this.init = function (element) {
      let parentElement = element ? element : $('body');
      init(parentElement);
    };
  }

  window.Common = new Common();

})(jQuery);
