(function ($) {

  "use strict";

  function SwiperSlider () {
    
    let initHeaderSlider = function ($swiper) {
      $swiper.each(function () {
        let $currentSwiper = $(this);

        let headerSwiper = new Swiper($currentSwiper, {
          speed: 400,
          threshold: 30,
          navigation: {
            nextEl: $currentSwiper.find('.swiper-button-next'),
            prevEl: $currentSwiper.find('.swiper-button-prev')
          },
          slidesPerView: 1,
          centeredSlides: true,
          spaceBetween: 0
        });
      });
    };

    let initTripleSlider = function ($swiper) {
      $swiper.each(function () {
        let $currentSwiper = $(this);

        let tripleSwiper = new Swiper($currentSwiper, {
          speed: 400,
          navigation: {
            nextEl: $currentSwiper.find('.swiper-button-next'),
            prevEl: $currentSwiper.find('.swiper-button-prev')
          },
          pagination: {
            el: $currentSwiper.find('.swiper-pagination'),
            type: 'bullets',
          },
          slidesPerView: 1,
          centeredSlides: true,
          spaceBetween: 10,
          breakpoints: {
            576: {
              slidesPerView: 2,
              spaceBetween: 20,
              centeredSlides: false,
              slidesPerGroup: 2
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
              centeredSlides: false,
              slidesPerGroup: 3
            }
          },
          hashNavigation: $currentSwiper.find('[data-hash]').length > 0
        });
      });
    };
    
    let init = function (parentElement) {
      // Single slider
      let $singleSlider = $('.js-single-slider', parentElement);
      if ($singleSlider.length) {
        initHeaderSlider($singleSlider);
      }

      // Homepage destinations slider
      let $tripleSwiper = $('.js-triple-slider', parentElement);
      if ($tripleSwiper.length) {
        initTripleSlider($tripleSwiper);
      }
    };

    this.init = function (element) {
      let parentElement = element ? element : $('body');
      init(parentElement);
    };
  }

  window.SwiperSlider = new SwiperSlider();

})(jQuery);
