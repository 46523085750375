let iconBase = App.mediaPath+'icons/';

// Initialize and add the map
function initContactMap () {
  let el = document.getElementById('contactMap');
  let contactMapCenterPosition = {lat: 45.25104835733601, lng: 13.607193567132525};

  let map = new google.maps.Map(el, {
    zoom: 15,
    center: contactMapCenterPosition,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    //draggable: false,
    //zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false
  });

  let infowindow = new google.maps.InfoWindow({
    content: 'Privatna specijalistička ginekološka ordinacija Lina Štefanić',
  });

  let marker = new google.maps.Marker({
    position: contactMapCenterPosition,
    map: map,
    icon: iconBase + 'map-marker.svg',
    animation: google.maps.Animation.DROP,
  });

  infowindow.open({
    anchor: marker,
    map,
    shouldFocus: false,
  });

  marker.addListener("click", () => {
    infowindow.open({
      anchor: marker,
      map,
      shouldFocus: false,
    });
  });
}

$(document).ready(function () {
  if ($('#contactMap').length) {
    initContactMap();
  }
});
